import { PERMISSION } from '@/Configs/Constants';
import { ROUTE_NAME } from '@/routes/constants';

import type { RouteRecord } from '@/routes/types';

const legacyReportingRoutes: RouteRecord[] = [
  {
    path: '/reports/old',
    component: () => import(/* webpackChunkName: "ReportingLegacy" */ '@/components/Reporting/Pages/Availability.vue'),
    meta: { permissionRequired: PERMISSION.REPORTING__GENERAL__MANAGE },
    name: ROUTE_NAME.REPORTING_LEGACY_DASHBOARD,
  },
  {
    path: '/reports/old/conversations',
    component: () => import(/* webpackChunkName: "ReportingLegacy" */ '@/components/Reporting/Pages/Conversations.vue'),
    meta: { permissionRequired: PERMISSION.REPORTING__GENERAL__MANAGE },
  },
  {
    path: '/reports/old/messages',
    component: () => import(/* webpackChunkName: "ReportingLegacy" */ '@/components/Reporting/Pages/Messages.vue'),
    meta: { permissionRequired: PERMISSION.REPORTING__GENERAL__MANAGE },
  },
  {
    path: '/reports/old/productivity',
    component: () => import(/* webpackChunkName: "ReportingLegacy" */ '@/components/Reporting/Pages/Productivity.vue'),
    meta: { permissionRequired: PERMISSION.REPORTING__GENERAL__MANAGE },
  },
  {
    path: '/reports/old/channels',
    component: () => import(/* webpackChunkName: "ReportingLegacy" */ '@/components/Reporting/Pages/Channels.vue'),
    meta: { permissionRequired: PERMISSION.REPORTING__GENERAL__MANAGE },
  },
  {
    path: '/reports/old/labels',
    component: () => import(/* webpackChunkName: "ReportingLegacy" */ '@/components/Reporting/Pages/Labels.vue'),
    meta: { permissionRequired: PERMISSION.REPORTING__GENERAL__MANAGE },
  },
  {
    path: '/reports/old/users',
    component: () => import(/* webpackChunkName: "ReportingLegacy" */ '@/components/Reporting/Pages/Users.vue'),
    meta: { permissionRequired: PERMISSION.REPORTING__GENERAL__MANAGE },
  },
  {
    path: '/reports/old/export',
    component: () =>
      import(/* webpackChunkName: "ReportingLegacy" */ '@/components/Reporting/Pages/DataExportPage.vue'),
    meta: { permissionRequired: PERMISSION.REPORTING__GENERAL__MANAGE },
  },
  {
    path: '/reports/old/time-tracking',
    component: () => import(/* webpackChunkName: "ReportingLegacy" */ '@/components/Reporting/Pages/TimeTracking.vue'),
    meta: { permissionRequired: PERMISSION.REPORTING__GENERAL__MANAGE },
  },
  {
    path: '/reports/old/csats',
    component: () => import(/* webpackChunkName: "ReportingLegacy" */ '@/components/Reporting/Pages/Csats.vue'),
    meta: { permissionRequired: PERMISSION.REPORTING__GENERAL__MANAGE },
  },
];

export default legacyReportingRoutes;
