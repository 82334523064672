import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withModifiers as _withModifiers, withCtx as _withCtx, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex flex-wrap items-center gap-y-1" }
const _hoisted_2 = { class: "ml-auto flex" }
const _hoisted_3 = { class: "flex rounded-lg border border-grey-200 font-bold" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_atomic_multiselect = _resolveComponent("atomic-multiselect")!
  const _component_t_button = _resolveComponent("t-button")!
  const _component_atomic_datepicker = _resolveComponent("atomic-datepicker")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["sticky top-0 z-50 mb-8 flex select-none flex-row flex-nowrap items-start bg-white", {
      'rounded-lg px-10 pb-6 pt-4': !$props.compactStyle,
      'mx-6 border-b-1 border-t-1 border-grey-200 py-3 xl2:mx-10': $props.compactStyle,
    }])
  }, [
    _createElementVNode("div", _hoisted_1, [
      ($props.showRating)
        ? (_openBlock(), _createBlock(_component_atomic_multiselect, {
            key: 0,
            modelValue: $data.selection.ratings,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($data.selection.ratings) = $event)),
            title: _ctx.$t('reports.filters_ratings'),
            label: "text",
            options: $options.ratings,
            "show-checkboxes": "true"
          }, null, 8 /* PROPS */, ["modelValue", "title", "options"]))
        : _createCommentVNode("v-if", true),
      _createVNode(_component_atomic_multiselect, {
        modelValue: $data.selection.channels,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($data.selection.channels) = $event)),
        title: _ctx.$t('reports.filters_channels'),
        label: "text",
        options: $options.channels
      }, null, 8 /* PROPS */, ["modelValue", "title", "options"]),
      ($props.showSurvey)
        ? (_openBlock(), _createBlock(_component_atomic_multiselect, {
            key: 1,
            modelValue: $data.selection.surveys,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (($data.selection.surveys) = $event)),
            title: _ctx.$t('reports.filters_surveys'),
            label: "text",
            options: _ctx.$root.surveys
          }, null, 8 /* PROPS */, ["modelValue", "title", "options"]))
        : _createCommentVNode("v-if", true),
      _createVNode(_component_atomic_multiselect, {
        modelValue: $data.selection.users,
        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (($data.selection.users) = $event)),
        title: _ctx.$t('reports.filters_users'),
        label: "text",
        options: $options.users,
        "show-avatars": "true"
      }, null, 8 /* PROPS */, ["modelValue", "title", "options"]),
      _createVNode(_component_atomic_multiselect, {
        modelValue: $data.selection.labels,
        "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => (($data.selection.labels) = $event)),
        title: _ctx.$t('reports.filters_labels'),
        label: "name",
        options: _ctx.$root.labels
      }, null, 8 /* PROPS */, ["modelValue", "title", "options"]),
      _createVNode(_component_t_button, {
        class: "w-fit",
        size: "sm",
        onClick: _withModifiers($options.exportCSAT, ["prevent"])
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('reports_v2.export')), 1 /* TEXT */)
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["onClick"])
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_atomic_datepicker, {
          modelValue: $data.selection.currentPeriod,
          "onUpdate:modelValue": [
            _cache[5] || (_cache[5] = ($event: any) => (($data.selection.currentPeriod) = $event)),
            $options.setPreviousPeriod
          ],
          mode: "range"
        }, null, 8 /* PROPS */, ["modelValue", "onUpdate:modelValue"])
      ])
    ])
  ], 2 /* CLASS */))
}