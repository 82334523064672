<template>
  <div
    class="sticky top-0 z-50 mb-8 flex select-none flex-row flex-nowrap items-start bg-white"
    :class="{
      'rounded-lg px-10 pb-6 pt-4': !compactStyle,
      'mx-6 border-b-1 border-t-1 border-grey-200 py-3 xl2:mx-10': compactStyle,
    }"
  >
    <div class="flex flex-wrap items-center gap-y-1">
      <atomic-multiselect
        v-if="showRating"
        v-model="selection.ratings"
        :title="$t('reports.filters_ratings')"
        label="text"
        :options="ratings"
        show-checkboxes="true"
      ></atomic-multiselect>
      <atomic-multiselect
        v-model="selection.channels"
        :title="$t('reports.filters_channels')"
        label="text"
        :options="channels"
      ></atomic-multiselect>
      <atomic-multiselect
        v-if="showSurvey"
        v-model="selection.surveys"
        :title="$t('reports.filters_surveys')"
        label="text"
        :options="$root.surveys"
      ></atomic-multiselect>
      <atomic-multiselect
        v-model="selection.users"
        :title="$t('reports.filters_users')"
        label="text"
        :options="users"
        show-avatars="true"
      ></atomic-multiselect>
      <atomic-multiselect
        v-model="selection.labels"
        :title="$t('reports.filters_labels')"
        label="name"
        :options="$root.labels"
      ></atomic-multiselect>

      <t-button class="w-fit" size="sm" @click.prevent="exportCSAT">
        {{ $t('reports_v2.export') }}
      </t-button>
    </div>

    <div class="ml-auto flex">
      <div class="flex rounded-lg border border-grey-200 font-bold">
        <atomic-datepicker
          v-model="selection.currentPeriod"
          mode="range"
          @update:model-value="setPreviousPeriod"
        ></atomic-datepicker>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { mapStores } from 'pinia';

import eventBus from '@/eventBus';
import { useUsersStore } from '@/store/pinia/users';

export default {
  props: {
    modelValue: {
      type: Object,
      default: () => {
        return {};
      },
    },
    showRating: {
      type: Boolean,
      default: false,
    },
    showSurvey: {
      type: Boolean,
      default: false,
    },
    compactStyle: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:modelValue'],
  data() {
    return {
      restoreFromUrl: false,
      selection: {
        ratings: [],
        channels: [],
        currentPeriod: {},
        previousPeriod: {},
        labels: [],
        users: [],
        surveys: [],
        excludeBusinessHours: true,
        timezone: this.$root.user.timezone,
      },
      date: {},
      surveys: [],
    };
  },
  computed: {
    ...mapStores(useUsersStore),
    users() {
      return this.usersStore.users;
    },
    channels() {
      return this.$root.channels.filter((c) => !c.is_private);
    },
    ratings() {
      return [
        {
          text: this.$t('reports.filters_ratings_option_negative'),
          id: '1',
          url: `${this.$root.assetsURL}img/csat/negative_emoji.svg`,
        },
        {
          text: this.$t('reports.filters_ratings_option_neutral'),
          id: '2',
          url: `${this.$root.assetsURL}img/csat/neutral_emoji.svg`,
        },
        {
          text: this.$t('reports.filters_ratings_option_positive'),
          id: '3',
          url: `${this.$root.assetsURL}img/csat/positive_emoji.svg`,
        },
      ];
    },
  },
  watch: {
    selection: {
      deep: true,
      handler() {
        const res = {
          currentPeriod: this.selection.currentPeriod,
          previousPeriod: this.selection.previousPeriod,
          interval: this.getInterval(),
          channels: this.selection.channels,
          users: this.selection.users,
          labels: this.selection.labels,
          excludeBusinessHours: this.selection.excludeBusinessHours,
          timezone: this.selection.timezone,
          ratings: this.selection.ratings,
          surveys: this.selection.surveys,
        };
        this.$emit('update:modelValue', res);
        this.$router.replace({ query: { filters: JSON.stringify(res) } });
      },
    },
  },
  mounted() {
    if (!this.$route.query.filters || this.$route.query.filters === 'undefined') {
      this.setDefaultPeriod();
    } else {
      this.restoreFiltersFromUrl();
    }
  },

  methods: {
    exportCSAT() {
      eventBus.$emit('exportCSAT');
    },

    restoreFiltersFromUrl() {
      try {
        const defaults = JSON.parse(this.$route.query.filters);
        this.restoreFromUrl = true;
        this.selection = {
          channels: defaults.channels,
          labels: defaults.labels,
          users: defaults.users,
          currentPeriod: defaults.currentPeriod,
          previousPeriod: defaults.previousPeriod,
          excludeBusinessHours: defaults.excludeBusinessHours,
          timezone: defaults.timezone,
          ratings: defaults.ratings,
          surveys: defaults.surveys,
        };
      } catch (e) {
        console.error(e);
        this.setDefaultPeriod();
      }
    },
    setPreviousPeriod() {
      const currentStart = moment.unix(this.selection.currentPeriod.start);
      const currentEnd = moment.unix(this.selection.currentPeriod.end);
      let diff = currentEnd.diff(currentStart, 'hours');
      //if currentPeriod includes leap days, substract 24hs for each leap day for previousPeriod calculation
      for (let year = currentStart.year(); year <= currentEnd.year(); year++) {
        const date = moment(year + '-02-29');
        if (date.isBetween(currentStart, currentEnd) && date.isLeapYear()) {
          diff -= 24;
        }
      }
      let previousStart = currentStart.clone().subtract(diff, 'hours').startOf('day');
      const previousEnd = currentStart.clone().subtract(1, 'day').endOf('day');
      //if previousPeriod includes leap days, rewind start date 24hs for each leap day
      for (let year = previousStart.year(); year <= previousEnd.year(); year++) {
        const date = moment(year + '-02-29');
        if (date.isBetween(previousStart, previousEnd) && date.isLeapYear()) {
          previousStart = previousStart.subtract(24, 'hours').startOf('day');
        }
      }
      this.selection.previousPeriod = {
        start: previousStart.unix(),
        end: previousEnd.unix(),
      };
    },
    setDefaultPeriod() {
      this.selection.previousPeriod = {
        start: moment().subtract(13, 'days').startOf('day').unix(),
        end: moment().subtract(7, 'days').endOf('day').unix(),
      };

      this.selection.currentPeriod = {
        start: moment().subtract(6, 'days').startOf('day').unix(),
        end: moment().endOf('day').unix(),
      };
    },
    getInterval() {
      const currentStart = moment.unix(this.selection.currentPeriod.start);
      const currentEnd = moment.unix(this.selection.currentPeriod.end);
      const diff = currentEnd.diff(currentStart, 'hours');

      if (diff < 24) {
        return 'hour';
      }
      if (diff < 744) {
        return 'day';
      }
      if (diff < 3720) {
        return 'week';
      }
      if (diff < 17531) {
        return 'month';
      }
      return 'year';
    },
  },
};
</script>
