<script type="text/babel">
import { includes, map } from 'lodash';
import Multiselect from 'vue-multiselect';

import eventBus from '@/eventBus';
export default {
  name: 'ChannelSelector',
  emits: ['selected', 'loaded'],
  data() {
    return {
      channels: [],
      channel_id: null,
      channel: null,
    };
  },

  components: {
    Multiselect,
  },

  watch: {
    channel(channel) {
      this.channel_id = channel.id;
      this.rememberChannel(channel.id);
    },
  },

  mounted() {
    this.loadChannels();
    eventBus.$on('voip.channels.changed', this.loadChannels);
  },

  methods: {
    loadChannels() {
      this.channels = this.$root.channels.filter((c) => {
        return c.type === 'VOIP' && ['TWILIO_TRENGO', 'TWILIO', 'TWILIO_SANDBOX'].includes(c.voipChannel?.provider);
      });
      this.$emit('loaded', this.channels);
      this.setDefaultChannel();
    },

    async setDefaultChannel() {
      if (!this.channels.length) {
        return;
      }

      let channel_id = await this.$tStorage.getItem(CHANNEL_PREFIX + '.default_voip_channel');
      if (includes(map(this.channels, 'id'), channel_id)) {
        this.channel = this.channels.filter((channel) => {
          return channel.id == channel_id;
        })[0];
      } else {
        this.channel = this.channels[0];
      }

      this.$emit('selected', this.channel);
    },

    rememberChannel(channel_id) {
      this.$tStorage.setItem(CHANNEL_PREFIX + '.default_voip_channel', channel_id);
    },

    select(channel) {
      this.$emit('selected', channel);
    },
  },
};
</script>

<template>
  <div class="channel-selector flex-1">
    <i class="material-icons absolute text-white" style="z-index: 9999; font-size: 20px; left: 25px; top: 10px">
      phone_forwarded
    </i>
    <multiselect
      v-model="channel"
      :options="channels"
      deselect-label=""
      placeholder="Loading channels.."
      :allow-empty="false"
      track-by="id"
      label="text"
      :searchable="false"
      :close-on-select="true"
      @select="select"
    ></multiselect>
  </div>
</template>

<style type="text/less">
.channel-selector {
  position: relative;
}
.channel-selector .multiselect__tags {
  border-radius: 0;
  background: #14b29f;
  border: none;
}
.channel-selector .multiselect__select:before {
  border-color: #fff transparent transparent;
}
.channel-selector .multiselect__single {
  background: #14b29f;
  color: white;
  padding-top: 2px;
  cursor: pointer;
  padding-left: 45px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
/*.channel-selector .multiselect__single:before {*/
/*    font-family: "Material Icons";*/
/*    content: "\e61c";*/
/*    font-size:18px;*/
/*    padding-right:5px;*/
/*}*/

.multiselect--active {
  z-index: 9999 !important;
}
</style>
